import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  Button,
  Column,
  CurvedSectionDivider,
  FormattedText,
  Gain,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionQuotes,
  SectionUseCases,
} from "../components";
import Text from "../components/Text";
import { fixedImage, fluidImage } from "../prismic-tools";
import Img from "gatsby-image";

export const pageQuery = graphql`
  query Product($uid: String!) {
    prismicProductPage(uid: { eq: $uid }) {
      uid
      data {
        top_section_main_title
        top_section_main_description {
          raw
        }
        top_section_main_button
        top_section_main_image {
          alt
          dimensions {
            width
            height
          }
          url
        }

        quote_author_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        quote_company_logo {
          alt
          dimensions {
            width
            height
          }
          url
        }
        quote_author_name
        quote_author_role
        quote_title
        quote_content {
          raw
        }

        gains {
          gain_title
          gain_pre_title
          gain_description {
            raw
          }
          gain_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
        }

        features_title

        extended_feature_title

        extended_feature {
          extended_feature_description_title
          extended_feature_description {
            raw
          }
        }

        workflow_feature_title

        workflow_feature {
          workflow_feature_description_title
          workflow_feature_description {
            raw
          }
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.ProductQuery;
};

export default function ProductPage({ data }: Props) {
  const product = data.prismicProductPage?.data;
  if (!product) {
    return null;
  }

  return (
    <Layout>
      <HelmetSeo {...product} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Grid alignColumns="center" reverseColumns>
            <Column size={6}>
              <MaxWidth width={528}>
                <Img fluid={fluidImage(product.top_section_main_image, 528)} />
              </MaxWidth>
            </Column>
            <Column size={1} />
            <Column size={5}>
              <Text typeface="h1" as="h1" bold>
                {product.top_section_main_title}
              </Text>
              <Gutter />
              <FormattedText>
                <RichText render={product.top_section_main_description?.raw} />
              </FormattedText>
              <Gutter />

              <HorizontalAlign align="flex-start">
                <Button
                  colorTheme="orange"
                  label={product.top_section_main_button}
                  to="/contact"
                />
              </HorizontalAlign>
            </Column>
          </Grid>
          <Gutter size={5} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        frontColor="newDark2"
        position="top"
        backColor="light1"
      />
      <SectionQuotes quotes={[product]} />
      <CurvedSectionDivider
        frontColor="newDark2"
        position="bottom"
        backColor="light1"
      />
      <Section>
        <PageMaxWidth>
          <Gutter size={6} md={2.5} />
          {product.gains?.map(
            (gain, i) =>
              gain && (
                <>
                  <Gain
                    reverseColumns={i % 2 === 0}
                    preTitle={gain.gain_pre_title}
                    title={gain.gain_title}
                    content={gain.gain_description}
                    image={gain.gain_image}
                  />
                  <Gutter size={6} md={2.5} />
                </>
              )
          )}
          <Grid>
            <Column size={12}>
              <Text typeface="h3" as="h3" bold>
                {product.features_title}
              </Text>
            </Column>
          </Grid>

          <Gutter size={2} />
          <Grid>
            <Column size={6}>
              <Text typeface="h5" bold>
                {product.extended_feature_title}
              </Text>
              <Gutter size={1.5} />
              {product.extended_feature?.map((feature) => {
                if (!feature) return null;
                return (
                  <React.Fragment
                    key={feature.extended_feature_description_title}
                  >
                    <Text>
                      <Text color="orange1">
                        {feature.extended_feature_description_title}
                      </Text>
                      <FormattedText>
                        <RichText
                          render={feature.extended_feature_description?.raw}
                        />
                      </FormattedText>
                    </Text>
                    <Gutter />
                  </React.Fragment>
                );
              })}
            </Column>
            <Column size={1} />
            <Column size={5}>
              <Text typeface="h5" bold>
                {product.workflow_feature_title}
              </Text>
              <Gutter />
              {product.workflow_feature?.map((feature) => {
                if (!feature) return null;
                return (
                  <React.Fragment
                    key={feature.workflow_feature_description_title}
                  >
                    <Text>
                      <Text color="orange1">
                        {feature.workflow_feature_description_title}
                      </Text>
                      <FormattedText>
                        <RichText
                          render={feature.workflow_feature_description?.raw}
                        />
                      </FormattedText>
                    </Text>
                    <Gutter />
                  </React.Fragment>
                );
              })}
            </Column>
          </Grid>
          <Gutter size={6} />
        </PageMaxWidth>
      </Section>

      <SectionUseCases />
      <Gutter size={6} />
    </Layout>
  );
}
